<ngx-spinner>
</ngx-spinner>

<!-- AREA USER STORY -->

<div class="notes-container mt-1">

  <div class="notes-toggle">


    <div class="d-flex">
      <h5 class="mr-auto">User Story</h5>
      <button class="btn btn-outline-dark mr-1 mb-1 mb-sm-0" label="Download Tests" (click)="downloadStory()">Export Story</button>

      <button *ngIf="isCollapsed"
              type="button"
              class="btn btn-outline-dark"
              (click)="isCollapsed = !isCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isCollapsed">
        {{ isCollapsed ? 'Mostra' : 'Chiudi' }}
      </button>

      <button *ngIf="!isCollapsed"
              type="button"
              class="btn btn-dark"
              (click)="isCollapsed = !isCollapsed"
              aria-controls="addnotes"
              [attr.aria-expanded]="!isCollapsed">
        {{ isCollapsed ? 'Mostra' : 'Chiudi' }}
      </button>

    </div>


  </div>

  <div class="notes-content" [ngClass]="{'show': !isCollapsed}" id="addnotes">
    <div class="card">

      <div class="card-body">
        <div class="scrollable-text">{{refinedStoryString}}</div>
      </div>
    </div>
  </div>
</div>

<!-- UPLOAD TEST -->
<div class="d-flex justify-content-between align-items-center mt-3" style="width: 100%;">
  <div class="form-group" style="flex: 1; max-width: 30%;">
    <label for="uploadHtmlFile">Import Workspace</label>
    <div class="custom-file">
      <input
        type="file"
        *ngIf="!testFileName"
        id="uploadHtmlFile"
        class="custom-file-input"
        accept=".html"
        (change)="onTestFileSelected('html')"
        #testFileInputHtml
      />
      <label for="uploadHtmlFile" class="custom-file-label" *ngIf="!testFileName">Choose file</label>
      <div class="input-group" *ngIf="testFileName">
        <input
          type="text"
          class="form-control"
          [(ngModel)]="testFileName"
          (ngModelChange)="testFileName = $event"
        />
        <div class="input-group-append">
          <button (click)="clearFile()" class="btn btn-danger">
            <i class="ft-trash-2"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="form-group" style="flex: 1; max-width: 30%;">
  <label for="templatemodal" class="gtt-label">Template</label>
  <ng-select id="templatemodal"
             placeholder="Seleziona un template"
             [items]="templateNames"
             [(ngModel)]="selectedTemplate"
             [clearable]="false"
             (change)="updateSelectedTemplate()">
  </ng-select>
</div>

<button type="button" class="gtt-generate-button" (click)="generateTests()">Genera</button>
-->

<!-- CARDS -->
<div class="test-info-card-container custom-margin">

  <div class="test-info-card selected-tests-card" *ngIf="isEditMode">
    <div class="card-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
        <polyline points="22 4 12 14.01 9 11.01"></polyline>
      </svg>
    </div>
    <div class="card-content">
      <h3 class="card-value">{{selectedCount}}/5</h3>
      <p class="card-label">Selected tests</p>
    </div>
  </div>

  <div class="test-info-card test-count-card" *ngIf="generatedTests.length > 0">
    <div class="card-icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
        <polyline points="14 2 14 8 20 8"></polyline>
        <line x1="16" y1="13" x2="8" y2="13"></line>
        <line x1="16" y1="17" x2="8" y2="17"></line>
        <polyline points="10 9 9 9 8 9"></polyline>
      </svg>
    </div>
    <div class="card-content">
      <h3 class="card-value">{{testCount}}</h3>
      <p class="card-label">Number of tests</p>
    </div>
  </div>

  <div class="test-info-card-genera coverage-card" *ngIf="generatedTests.length > 0"
       (click)="openGeneraAltriTestsModal('append', generaTestModal)"
       style="cursor: pointer;">
    <div class="card-icon-genera">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
        <path d="M14 2v6h6"></path>
        <line x1="12" y1="11" x2="12" y2="17"></line>
        <line x1="9" y1="14" x2="15" y2="14"></line>
      </svg>
    </div>
    <div class="card-content-genera">
      <h3 class="card-value-genera">Genera</h3>
      <p class="card-label-genera">Nuovi Test</p>
    </div>
  </div>

  <app-coverage></app-coverage>
</div>

<!-- Add this somewhere in your template -->
<div id="templateContentContainer" style="display: none;"></div>

<!-- pulsanti sulla tabella -->
<div class="row-container" *ngIf="generatedTests.length > 0">
  <div class="left-content">
    <button (click)="toggleEditMode()"
            [ngClass]="isEditMode ? 'btn btn-dark mr-1 mb-1 mb-sm-0' : 'btn btn-outline-dark mr-1 mb-1 mb-sm-0'">
      {{ isEditMode ? 'Exit Edit Mode' : 'Enter Edit Mode' }}    </button>
    <button *ngIf="selectedCount > 0" class="btn btn-success mr-1 mb-1 mb-sm-0" label="Rigenera Selezionati" (click)="openRigeneraMultipliTestModal(rigeneraModal)">Rigenera Selezionati</button>
    <button *ngIf="hasSubtable" (click)="toggleAllSubTables()"
            [ngClass]="allSubTablesExpanded ? 'btn btn-dark mr-1 mb-1 mb-sm-0' : 'btn btn-outline-dark mr-1 mb-1 mb-sm-0'">
      {{ allSubTablesExpanded ? 'Hide All Sub-Tables' : 'Show All Sub-Tables' }}
    </button>
  </div>
  <div class="right-content">
    <button
      class="btn btn-outline-dark mr-1 mb-1 mb-sm-0"
      data-toggle="tooltip"
      data-placement="top"
      title="Effettua l'esportazione dei test, della story e delle note"
      (click)="downloadGeneratedTests()">
      Export Workspace
    </button>
    <button class="btn btn-outline-dark mr-1 mb-1 mb-sm-0" label="Download Tests" (click)="downloadCSVGeneratedTests()">Export Tests CSV</button>

    <button class="btn btn-success mb-1 mb-sm-0" (click)="salvaTests()">Conferma Tests</button>
  </div>
</div>

<!-- styled-test-table.component.html -->
<div class="test-table-container" *ngIf="generatedTests.length > 0">
  <table class="test-styled-table">
    <thead>
    <tr>
      <th *ngIf="isEditMode" class="test-table-header"></th>
      <th *ngFor="let column of columns" class="test-table-header">{{ column }}</th>
      <th *ngIf="!isEditMode" class="test-table-header">Actions</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let test of generatedTests; let i = index" class="test-table-row">
      <td *ngIf="isEditMode" class="test-table-cell">
        <input type="checkbox"
               [disabled]="!test.isSelected && selectedCount >= 5"
               [(ngModel)]="test.isSelected"
               (change)="onSelectionChange(test)">
      </td>
      <td *ngFor="let column of columns" class="test-table-cell">
        <ng-container *ngIf="!isSubTable(test[column]); else subTableTemplate">
          <ng-container *ngIf="!isEditMode">
            <ng-container *ngIf="!isArray(test[column]); else listTemplate">
              <span>{{ test[column] }}</span>
            </ng-container>
            <ng-template #listTemplate>
              <ul class="cell-list">
                <li *ngFor="let item of test[column]">{{ item }}</li>
              </ul>
            </ng-template>
          </ng-container>
          <div *ngIf="isEditMode"
               [innerHTML]="test[column]"
               (blur)="updateContent(test, column, $event)"
               contenteditable="true"
               class="edit-div">
          </div>
        </ng-container>
        <ng-template #subTableTemplate>
          <ng-container *ngIf="!allSubTablesExpanded">
            <span class="sub-table-summary">{{ getSubTableRowCount(test[column]) }} rows</span>
          </ng-container>
          <table [class.d-none]="!allSubTablesExpanded" class="sub-table">
            <thead>
            <tr>
              <th *ngFor="let subColumn of getSubTableColumns(test[column])">{{ subColumn }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let row of getSubTableRows(test[column])">
              <td *ngFor="let subColumn of getSubTableColumns(test[column])">{{ row[getSubTableColumns(test[column]).indexOf(subColumn)] }}</td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </td>
      <td *ngIf="!isEditMode" class="test-table-cell test-table-actions">
        <button (click)="openEditModalTest(test, testModal)" class="test-table-btn test-table-btn-edit">Edit</button>
        <button (click)="openRigeneraSingoloTestModal(test, rigeneraModal)" class="test-table-btn test-table-btn-regenerate">Rigenera</button>
        <button (click)="openScomponiTestModal(test, rigeneraModal)" class="test-table-btn test-table-btn-decompose">Scomponi</button>
        <button (click)="deleteTest(i)" class="test-table-btn test-table-btn-delete">Delete</button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<!-- Modal Genera -->

<ng-template #generaTestModal let-modal>
  <div class="gtt-modal-content">
    <div class="gtt-modal-header">
      <h3 class="gtt-modal-title">Genera Test</h3>
      <button type="button" class="gtt-close-button" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
    </div>
    <div class="gtt-modal-body">
      <div class="gtt-form-group">
        <label for="modelmodal" class="gtt-label">Modello</label>
        <ng-select id="modelmodal"
                   placeholder="Seleziona modello"
                   [items]="models"
                   [(ngModel)]="selectedModel"
                   [clearable]="false"
                   (change)="updateSelectedModel()">
        </ng-select>
      </div>

      <div class="gtt-form-group">
        <label class="gtt-label">Stile dei Test</label>
        <div class="gtt-radio-group">
          <label class="gtt-radio-label">
            <input type="radio" name="options" [(ngModel)]="testStyle" (change)="updateSelectedTestStyle()" value="Generico">
            <span class="gtt-radio-text">Generico</span>
          </label>
          <label class="gtt-radio-label">
            <input type="radio" name="options" [(ngModel)]="testStyle" (change)="updateSelectedTestStyle()" value="Atomic">
            <span class="gtt-radio-text">Post Condizione Atomica</span>
          </label>
        </div>
      </div>

      <div class="gtt-form-group" *ngIf="azione == 'append'">
        <label for="numTests" class="gtt-label">Numero di test da generare</label>
        <input type="number" id="numTests" class="gtt-input" min="1" max="10" [(ngModel)]="numTestsToGenerate" (change)="updateNumTestsToGenerate()">
      </div>

      <div class="gtt-form-group">
        <label for="input" class="gtt-label">Istruzioni (Facoltativo)</label>
        <textarea id="input" class="gtt-textarea" placeholder="Qui puoi dare delle indicazioni sui test da generare" [(ngModel)]="istruzioniScriviTest" (change)="updateIstruzioniScriviTest()" rows="5"></textarea>
      </div>
    </div>
    <div *ngIf="azione == 'write'" class="gtt-modal-footer">
      <button type="button" class="gtt-generate-button" (click)="generateTests()">Genera</button>
    </div>
    <div *ngIf="azione == 'append'" class="gtt-modal-footer">
      <button type="button" class="gtt-generate-button" (click)="openGeneraAltriTestsModal2(rigeneraModal)">Genera</button>
    </div>
  </div>
</ng-template>

<!-- Modal Edit -->
<ng-template #testModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="testModalLabel">Edit Test Case</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedTest">
      <div *ngFor="let column of columns" class="form-group">
        <ng-container [ngSwitch]="true">
          <!-- For simple text inputs -->
          <ng-container *ngSwitchCase="isSimpleInput(selectedTest[column])">
            <label [for]="column">{{ column }}</label>
            <input [type]="getInputType(selectedTest[column])" [id]="column" class="form-control" [(ngModel)]="selectedTest[column]">
          </ng-container>

          <!-- For textarea inputs -->
          <ng-container *ngSwitchCase="isLongText(selectedTest[column])">
            <label [for]="column">{{ column }}</label>
            <textarea [id]="column" class="form-control" [(ngModel)]="selectedTest[column]" rows="3"></textarea>
          </ng-container>

          <!-- For array inputs -->
          <ng-container *ngSwitchCase="isArray(selectedTest[column]) && !isSubTable(selectedTest[column])">
            <label>{{ column }}</label>
            <div *ngFor="let item of selectedTest[column]; let i = index; trackBy: trackByFn" class="input-group mb-2">
              <input type="text" class="form-control" [(ngModel)]="selectedTest[column][i]">
              <div class="input-group-append">
                <button class="btn btn-outline-danger" type="button" (click)="removeArrayItem(column, i)">Remove</button>
              </div>
            </div>
            <button class="btn btn-outline-primary" (click)="addArrayItem(column)">Add Item</button>
          </ng-container>

          <!-- For sub-table inputs -->
          <ng-container *ngSwitchCase="isSubTable(selectedTest[column])">
            <label>{{ column }}</label>
            <table class="table table-bordered">
              <thead>
              <tr>
                <th *ngFor="let header of getSubTableHeaders(selectedTest[column])">{{ header }}</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of getSubTableRows(selectedTest[column]); let i = index; trackBy: trackByFn">
                <td *ngFor="let header of getSubTableHeaders(selectedTest[column])">
                  <input type="text" class="form-control" [(ngModel)]="row[header]">
                </td>
                <td>
                  <button class="btn btn-outline-danger" (click)="removeSubTableRow(column, i)">Remove</button>
                </td>
              </tr>
              </tbody>
            </table>
            <button class="btn btn-outline-primary" (click)="addSubTableRow(column)">Add Row</button>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">Close</button>
    <button type="button" class="btn btn-primary" (click)="saveEditedTest()">Save changes</button>
  </div>
</ng-template>

<!-- Modal Rigenera -->

<ng-template #rigeneraModal let-modal>
  <ngx-spinner>
  </ngx-spinner>
  <div class="modal-header">
    <h5 class="modal-title" id="rigeneraModalLabel">Edit Test Case</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="test-table-container" *ngIf="selectedTestCopy && selectedTestCopy.length > 0">
      <table class="test-styled-table">
        <thead>
        <tr>
          <th *ngIf="isEditMode" class="test-table-header"></th>
          <th *ngFor="let column of columns" class="test-table-header">{{ column }}</th>
          <th *ngIf="!isEditMode" class="test-table-header">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let test of selectedTestCopy; let i = index" class="test-table-row">
          <td *ngIf="isEditMode" class="test-table-cell">
            <input type="checkbox"
                   [disabled]="!test.isSelected && selectedCount >= 5"
                   [(ngModel)]="test.isSelected"
                   (change)="onSelectionChange(test)">
          </td>
          <td *ngFor="let column of columns" class="test-table-cell">
            <ng-container *ngIf="!isSubTable(test[column]); else subTableTemplate">
              <ng-container *ngIf="!isEditMode">
                <ng-container *ngIf="!isArray(test[column]); else listTemplate">
                  <span>{{ test[column] }}</span>
                </ng-container>
                <ng-template #listTemplate>
                  <ul class="cell-list">
                    <li *ngFor="let item of test[column]">{{ item }}</li>
                  </ul>
                </ng-template>
              </ng-container>
              <div *ngIf="isEditMode"
                   [innerHTML]="test[column]"
                   (blur)="updateContent(test, column, $event)"
                   contenteditable="true"
                   class="edit-div">
              </div>
            </ng-container>
            <ng-template #subTableTemplate>
              <button (click)="toggleSubTable(test, column)" class="toggle-btn">
                {{ test[column + '_expanded'] ? 'Hide' : 'Show' }} {{ column }}
              </button>
              <table *ngIf="test[column + '_expanded']" class="sub-table">
                <thead>
                <tr>
                  <th *ngFor="let subColumn of getSubTableColumns(test[column])">{{ subColumn }}</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of getSubTableRows(test[column])">
                  <td *ngFor="let subColumn of getSubTableColumns(test[column])">{{ row[getSubTableColumns(test[column]).indexOf(subColumn)] }}</td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </td>
          <td *ngIf="!isEditMode" class="test-table-cell test-table-actions">
            <button class="test-table-btn test-table-btn-delete" (click)="deleteTestRigenerato(i)">Delete</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!flgAppendTests">
    <textarea rows="3" cols="50" class="form-control" placeholder="Indicazioni sulle modifiche da apportare" [(ngModel)]="indicazioniRigeneraSingoloTest"></textarea>
    <button type="button" class="btn btn-primary" (click)="rigeneraSingoloTest(true)">Rigenera</button>
    <button type="button" class="btn btn-success" (click)="saveRigeneratoTest()">Save changes</button>
  </div>
  <div class="modal-footer" *ngIf="flgAppendTests">
    <textarea rows="3" cols="50" class="form-control" placeholder="Inserisci indicazioni su come scrivere il caso di test" [(ngModel)]="indicazioniRigeneraSingoloTest"></textarea>
    <button type="button" class="btn btn-primary" (click)="rigeneraSingoloTest(true)">Rigenera</button>
    <button type="button" class="btn btn-success" (click)="appendTests()">Aggiungi Tests</button>
  </div>
</ng-template>



