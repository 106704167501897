import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import { apiUrl } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

export interface NoteResponse {
  id: string;
  content: string;
  date: string;
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl = apiUrl

  constructor(private http: HttpClient, private authService: AuthService) { }

  private getHeaders(): Observable<HttpHeaders> {
    return this.authService.token$.pipe(
      switchMap(token => {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return new Observable<HttpHeaders>(observer => {
          observer.next(headers);
          observer.complete();
        });
      })
    );
  }

  getHello(): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers => this.http.get(`${this.apiUrl}/`, { headers })),
      catchError(this.handleError)
    );
  }

  saveTest(
    projectId: string,
    email: string,
    model: string,
    templateColumns: any[],
    story: string,
    test: any[],
    instructions: string,
  ): Observable<any> {
    function customSerialize(data: any[]): string {
      return JSON.stringify(data.map(item => {
        if (typeof item === 'object' && item !== null) {
          return Object.keys(item).map(key => `${key}:${item[key]}`).join(';');
        }
        return String(item);
      }));
    }

    const testData = {
      project_id: projectId,
      email: email,
      model: model,
      template_columns: customSerialize(templateColumns),
      story: story,
      test: customSerialize(test),
      instructions: instructions,
    };

    console.log('About to send saveTest request', testData);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.apiUrl}/savetest/`, testData, { headers })
      ),
      tap(
        response => console.log('SaveTest response received:', response),
        error => console.error('SaveTest error occurred:', error)
      ),
      catchError(this.handleError)
    );
  }

  analyzeCoverage(story: string, test: string, model: string): Observable<string> {
    const request = { story, test, model };
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<string>(`${this.apiUrl}/analyzecoverage/`, request, { headers })
      ),
      catchError(this.handleError)
    );
  }

  saveNotes(project: string, additional_context: string): Observable<any> {
    console.log(`notes aggiornate per il progetto ${project}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.apiUrl}/savenotes/`, { project, additional_context }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  retrieveNotes(project: string): Observable<any> {
    // console.log(`Fetching notes for project ${project}`);
    return this.http.post<any>(`${this.apiUrl}/retrievenotes/`, { project })
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  retrieveAllNotes(project: string): Observable<NoteResponse[]> {
    console.log('progetto debug: ' + project)
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<NoteResponse[]>(`${this.apiUrl}/retrieveallnotes/`, { project }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  retrieveSimilarNotes(project: string, story: string): Observable<NoteResponse[]> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<NoteResponse[]>(`${this.apiUrl}/retrieve_similar_notes/`, { project, story }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  deleteNote(project: string, noteId: string): Observable<any> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.delete(`${this.apiUrl}/deletenote/`, {
          headers,
          body: { project, note_id: noteId }
        })
      ),
      catchError(this.handleError)
    );
  }

  updateNote(project: string, noteId: string, newContent: string): Observable<NoteResponse> {
    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<NoteResponse>(`${this.apiUrl}/editnote/`, { project, note_id: noteId, new_content: newContent }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    console.log('Iniziando upload della storia');

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.apiUrl}/uploadfile/`, formData, { headers }).pipe(
          tap(response => {
            console.log('Risposta del server:', response);
            console.log('Upload della storia completato');
          })
        )
      ),
      catchError(error => {
        console.error('Errore durante l\'upload:', error);
        return this.handleError(error);
      })
    );
  }

  uploadFileTests(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post(`${this.apiUrl}/uploadtest/`, formData, { headers })
      ),
      catchError(this.handleError)
    );
  }

  generateTestCases(
    project: string,
    user: string,
    model: string,
    language: string,
    templateDesc: string | null | undefined,
    template: any[],
    story: string,
    style: string,
    instructions?: string
  ): Observable<any> {
    // Safely handle templateDesc, ensuring it's a string and setting a default if it's empty, null, or undefined
    const finalTemplateDesc = (typeof templateDesc === 'string' && templateDesc.trim() !== '')
      ? templateDesc.trim()
      : 'No description provided';

    console.log(`generateTestCases called with parameters:
      modelName: ${model},
      style: ${style},
      instructions: ${instructions},
      templateDesc: ${finalTemplateDesc}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<any>(`${this.apiUrl}/generatest/`, {
          project,
          user,
          model,
          language,
          templateDesc: finalTemplateDesc,
          template,
          story,
          style,
          instructions
        }, { headers })
      ),
      tap(response => {
        console.log('Response from generateTestCases:', response);
      }),
      catchError(this.handleError)
    );
  }

  generateMoreTests(story: string, test: string[], model: string, language: string, templateDesc: string | null | undefined, template: any[], instructions: string, numTest: string): Observable<any> {
    // Safely handle templateDesc, ensuring it's a string and setting a default if it's empty, null, or undefined
    const finalTemplateDesc = (typeof templateDesc === 'string' && templateDesc.trim() !== '')
      ? templateDesc.trim()
      : 'No description provided';
    console.log(`generateMoreTests called with parameters:
    model: ${model},
    instructions: ${instructions}`);


    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<any>(`${this.apiUrl}/generaaltritest/`, {
          story,
          test,
          model,
          language,
          templateDesc: finalTemplateDesc,
          template,
          instructions,
          numTest
        }, { headers })
      ),
      tap(response => {
        console.log('Response from generateTestCases:', response);
      }),
      catchError(this.handleError)
    );
  }

  regenerateSingoloTest(
    selectedModel: string,
    language: string,
    templateDesc: string | null | undefined,
    template: any[],
    originalTest: any,
    refinedStoryString?: string,
    instructions?: string
  ): Observable<any[]> {
    // Safely handle templateDesc, ensuring it's a string and setting a default if it's empty, null, or undefined
    const finalTemplateDesc = (typeof templateDesc === 'string' && templateDesc.trim() !== '')
      ? templateDesc.trim()
      : 'No description provided';

    console.log(`regenerateSingoloTest called with parameters:
    input test: ${JSON.stringify(originalTest)},
    instructions: ${instructions}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<any[]>(`${this.apiUrl}/rigenerasingolotest/`, {
          selectedModel,
          language,
          templateDesc: finalTemplateDesc,
          template,
          originalTest,
          instructions,
          refinedStoryString
        }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  generaDecisionTable(selectedModel: string, refinedStoryString: string): Observable<any> {
    console.log(`generaDecisionTable called with parameters:
    selectedModel: ${selectedModel}`);

    return this.http.post<any>(`${this.apiUrl}/generatable/`, {selectedModel, refinedStoryString })
      .pipe(
        catchError(this.handleError.bind(this))
      );
  }

  saveConfiguration(projectId: string, templateName: string, templateDesc: string, columns: any): Observable<any> {
    const url = `${this.apiUrl}/saveConfiguration/`;
    console.log(`saveConfiguration called with projectId: ${projectId}, templateName: ${templateName}, templateDesc: ${templateDesc}, columns: ${JSON.stringify(columns)}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.post<any>(url, { projectId, templateName, templateDesc, columns }, { headers })
      ),
      catchError(this.handleError)
    );
  }

  getTemplates(projectId: string): Observable<any> {
    const url = `${this.apiUrl}/getTemplates/${projectId}`;
    console.log(`getTemplates called with projectId: ${projectId}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<any>(url, { headers })
      ),
      catchError(this.handleError)
    );
  }

  setDefaultTemplate(projectId: string, templateName: string): Observable<any> {
    const url = `${this.apiUrl}/setDefaultTemplate/${encodeURIComponent(projectId)}/${encodeURIComponent(templateName)}`;
    console.log(`setDefaultTemplate called with projectId: ${projectId}, templateName: ${templateName}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.put<any>(url, {}, { headers })
      ),
      catchError(this.handleError)
    );
  }

  getTemplateDetails(projectId: string, templateName: string): Observable<any> {
    const url = `${this.apiUrl}/getTemplateDetails/${projectId}/${templateName}`;
    console.log(`getTemplateDetails called with projectId: ${projectId}, templateName: ${templateName}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.get<any>(url, { headers })
      ),
      catchError(this.handleError)
    );
  }

  deleteConfig(projectId: string, templateName: string): Observable<any> {
    const url = `${this.apiUrl}/deleteConfig/${encodeURIComponent(projectId)}/${encodeURIComponent(templateName)}`;
    console.log(`deleteConfig called with projectId: ${projectId}, templateName: ${templateName}`);

    return this.getHeaders().pipe(
      switchMap(headers =>
        this.http.delete(url, { headers })
      ),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // Network error (server is off or no network connection)
      console.error('Network error:', error.message);
    } else if (error.status >= 400 && error.status < 500) {
      // Client-side error
      console.error(`Client-side error: ${error.status}, body was: ${error.error}`);
    } else if (error.status >= 500) {
      // Server-side error
      console.error(`Server-side error: ${error.status}, body was: ${error.error}`);
    } else {
      // Other errors
      console.error(`Unexpected error: ${error.status}, body was: ${error.error}`);
    }
    return throwError('Something bad happened; please try again later.');
  }
}
